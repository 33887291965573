import { produce } from "immer";
import _ from "lodash";
import { useState } from "react";
import { v1 } from "uuid";
import Alert from "~/components/alert";
import Button from "~/components/button";
import { IconTrash } from "~/components/icons";
import type { ModalProps } from "~/components/modal";
import Modal from "~/components/modal";
import { RemixForm } from "~/components/remix-form";
import { Buttons } from "~/components/remix-form/buttons";
import { Errors } from "~/components/remix-form/errors";
import { Input } from "~/components/remix-form/input";
import { useOptionalProject } from "~/contexts";
import type {
  CandidateQuery,
  EditProjectTeamQuery,
  JobOpeningQuery,
  ProjectUserInput,
  TeamFieldsFragment
} from "~/types/api";
import { useFetcherData } from "~/utils/remix";

type OuterProps = ModalProps & {
  source: { __typename: "Project" | "Candidate" | "JobOpening"; id: string };
};

type InnerProps = ModalProps & {
  source:
    | TeamFieldsFragment
    | CandidateQuery["candidate"]
    | JobOpeningQuery["jobOpening"];
};

export default function TeamForm({ source, ...props }: OuterProps) {
  const fetcher = useFetcherData<
    EditProjectTeamQuery | CandidateQuery | JobOpeningQuery
  >(
    source.__typename === "Project"
      ? `/resources/projects/${source.id}/edit-team`
      : source.__typename === "Candidate"
        ? `/resources/candidates/${source.id}`
        : source.__typename === "JobOpening"
          ? `/resources/job-openings/${source.id}`
          : undefined
  );

  return fetcher.data && "project" in fetcher.data ? (
    <Form source={fetcher.data.project} {...props} />
  ) : fetcher.data && "candidate" in fetcher.data ? (
    <Form source={fetcher.data.candidate} {...props} />
  ) : fetcher.data && "jobOpening" in fetcher.data ? (
    <Form source={fetcher.data.jobOpening} {...props} />
  ) : null;
}

const Form = ({ source, onClose }: InnerProps) => {
  const project = useOptionalProject();

  const data =
    source.__typename === "Project"
      ? {
          id: source.id,
          pcId: source.pc?.id,
          pmId: source.pm?.id,
          technicalleadId: source.technicalLead?.id,
          opportunityOwnerId: source.opportunityOwner?.id
        }
      : {
          id: source.id
        };

  const [projectUsers, setProjectUsers] = useState<
    (ProjectUserInput & { uuid: string })[]
  >(
    source.projectUsers.map((pu) => ({
      id: pu.id,
      role: pu.role,
      userId: pu.user.id,
      uuid: v1()
    }))
  );

  const add = () =>
    setProjectUsers((users) => [
      ...users,
      { role: "Other", userId: "", uuid: v1() }
    ]);

  const remove = (index: number) =>
    setProjectUsers((users) =>
      users[index].id
        ? [
            ...users.slice(0, index),
            { ...users[index], delete: true },
            ...users.slice(index + 1)
          ]
        : [...users.slice(0, index), ...users.slice(index + 1)]
    );

  const change = (index: number, field: string, value: string) =>
    setProjectUsers(
      produce((draft) => {
        _.set(draft[index], field, value);
      })
    );

  return (
    <Modal onExplicitClose={onClose}>
      <RemixForm
        data={data}
        fetcher
        action={
          source.__typename === "Project"
            ? "/resources/projects/save"
            : source.__typename === "Candidate"
              ? "/resources/candidates/save"
              : "/resources/job-openings/save"
        }
        onSuccess={onClose}
      >
        <Modal.Header title="Edit Project Team" />
        <Modal.Body>
          {source.__typename === "Candidate" && (
            <Alert>
              All staff with <strong>Hiring</strong> permissions already have
              access. This is only to add other team members so they can add
              interview notes on a candidate.
            </Alert>
          )}
          <Errors />

          {source.__typename === "Project" && (
            <>
              {source.mode === "Opportunity" && (
                <Input
                  name="opportunityOwnerId"
                  type="user"
                  label="Opportunity Owner"
                  placeholder="None Assigned"
                />
              )}
              <div className="row">
                <div
                  className={
                    source.mode === "Campaign" ? "col-md-6" : "col-md-4"
                  }
                >
                  <Input
                    name="pcId"
                    type="user"
                    placeholder="None Assigned"
                    label={
                      project?.mode === "Campaign"
                        ? "Campaign Coordinator"
                        : "Project Coordinator"
                    }
                  />
                </div>
                <div
                  className={
                    source.mode === "Campaign" ? "col-md-6" : "col-md-4"
                  }
                >
                  <Input
                    name="pmId"
                    type="user"
                    placeholder="None Assigned"
                    label={
                      project?.mode === "Campaign"
                        ? "Campaign Manager"
                        : "Project Manager"
                    }
                  />
                </div>
                {source.mode !== "Campaign" && (
                  <div className="col-md-4">
                    <Input
                      name="technicalleadId"
                      type="user"
                      placeholder="None Assigned"
                      label="Technical Lead"
                    />
                  </div>
                )}
              </div>
            </>
          )}
          <label>Others</label>
          <input
            type="hidden"
            name="projectUsers"
            value={JSON.stringify(projectUsers)}
          />
          {projectUsers.map((pu, index) =>
            pu.delete ? null : (
              <div className="flex justify-between space-x-2" key={pu.uuid}>
                <div style={{ flex: 3 }}>
                  <Input
                    name={`projectUsers.${index}.userId`}
                    value={projectUsers[index].userId}
                    onChange={(_name, value) => change(index, "userId", value)}
                    type="user"
                    isClearable={false}
                    noLabel
                  />
                </div>
                {project && project.mode !== "Campaign" && (
                  <div style={{ flex: 2 }}>
                    <Input
                      name={`projectUsers.${index}.role`}
                      value={projectUsers[index].role}
                      onChange={(_name, value) => change(index, "role", value)}
                      type="combo"
                      isClearable={false}
                      options={ROLES}
                      noLabel
                    />
                  </div>
                )}
                <div>
                  <Button onClick={() => remove(index)}>
                    <IconTrash />
                  </Button>
                </div>
              </div>
            )
          )}

          {!projectUsers.filter((pu) => !pu.delete).length && (
            <p className="help-block">No other staff...</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Buttons modal primaryLabel="Update Staff">
            <Button onClick={add} mode="success">
              Add a Person
            </Button>
          </Buttons>
        </Modal.Footer>
      </RemixForm>
    </Modal>
  );
};

const ROLES = ["Expert", "TC", "TA", "PA", "Other"];
